@import "main.css";
// @import 'variables';
@import "button";

$heading: 5.333em; // 80px
$subHeading: 2.667em; // 40px
$paragraph: 1.667em; // 25px
$mHeading: 1.333em; // 20px
$baseText: 1em; // 15px

// colors
$coldGreen: #004650;
$brightGreen: #00dc96;
$coldGrey: #1e1e1e;
$silverGrey: #939393;
$lightGrey: #e6e6e6;
$white: #fff;

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 15px;
  font-family: Neue Haas Unica Pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  background-color: $lightGrey;
  margin: 0;
  overflow-x: hidden;
}

.container {
  background-color: $white;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
  }
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 22px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  &.align-start {
    align-items: flex-start;
  }
}

.column {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.paragraph {
  font-size: $paragraph;
  line-height: 35px;
  color: $lightGrey;
}

.heading {
  font-size: $heading;
  line-height: 75px;
  margin: 15px 0;
  font-weight: normal;
  @media (max-width: 1024px) {
    font-size: $subHeading;
    line-height: 1;
  }
}

%more-than-half {
  @media (min-width: 768px) {
    width: 58%;
  }
}
%less-than-half {
  @media (min-width: 768px) {
    width: 42%;
  }
}

%half {
  @media (min-width: 768px) {
    width: 50% !important;
  }
}

.col-6 {
  @extend %half;
}

.img-fluid {
  width: 100%;
  height: auto;
  //   max-width: 400px;
}

.intro {
  // height: 589px;
}

.hide-m {
  @media (max-width: 767px) {
    display: none !important;
  }
}
.hide-t {
  @media (min-width: 768px) {
    display: none !important;
  }
}

nav {
  background-color: $white;
  padding: 20px 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    background-color: $coldGrey;
    padding: 15px;
  }

  .brand {
    .logo {
      height: 24px;
    }
  }
  .links {
    a {
      color: $coldGrey;
      margin: 0 15px;
      text-decoration: none;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }

    &-mobile {
      background-color: $coldGrey;
      visibility: hidden;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      position: absolute;
      left: 0;
      top: 65px;
      width: 100%;
      padding: 30px 30px 30px 15px;
      transition: all 0.35s ease;
      border-top: 1px solid $white;

      a {
        text-decoration: none;
        margin: 10px 0;
        color: $white;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////
.intro-1,
.intro-6 {
  background: $coldGreen;
  color: $white;
  padding-left: 7%;
  width: 58%;
  height: 589px;
  .head {
    width: 90%;
    color: #e6e6e6;
    font-size: $baseText;
    border-bottom: 1px solid $brightGreen;
    padding-bottom: 8px;
  }
  p {
    width: 90%;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    background-color: #191919;
    padding: 30px 15px 0;
    .head {
      width: 100%;
    }
    .text-m {
      background-color: $coldGreen;
    }
  }
}

.intro-img-1,
.intro-img-6 {
  background-color: $coldGreen;
  padding: 15px 15px 40px;
}

.intro-6 {
  @media (max-width: 767px) {
    background: $coldGreen;
  }
  .heading {
    color: $brightGreen;
  }
}
.intro-img-6 {
  background-color: $white;
}

.img-field {
  @media (min-width: 768px) {
    width: 42%;
    height: 589px;
    background-color: #191919;
  }
}

////////////////////////////////////////////////////////////////////////////

.intro-2,
.intro-4 {
  border-top: 1px solid $coldGreen;
  border-bottom: 1px solid $coldGreen;
  @media (max-width: 767px) {
    margin-right: 15px;
    margin-left: 15px;
  }
  &__box1 {
    padding-left: 15px;
    .heading {
      color: $coldGreen;
    }
    @media (max-width: 767px) {
      border-bottom: 1px solid $coldGreen;
    }
    @media (min-width: 768px) {
      //height: 417px;
      width: 42%;
      padding-left: 7%;
    }
  }
  &__box2 {
    border-left: 1px solid $coldGreen;
    .paragraph {
      color: $coldGreen;
      @media (min-width: 768px) {
        width: 77%;
      }
    }
    @media (max-width: 767px) {
      margin-left: 60px;
      padding: 0 15px;
      margin-right: 30px;
    }
    @media (min-width: 768px) {
      //height: 417px;
      width: 58%;
      padding-left: 7%;
    }
  }
}

.intro-4 {
  &__box1 {
    @extend %half;
    @media (min-width: 768px) {
      //height: 694px;
    }
  }
  &__box2 {
    @extend %half;
    .paragraph {
      margin-bottom: 40px;
      @media (min-width: 768px) {
        width: 85%;
      }
    }
    @media (min-width: 768px) {
      //height: 694px;
    }
    @media (max-width: 767px) {
      .row {
        margin-bottom: 0;
      }
    }
  }
  .item {
    color: $coldGrey;
    @media (min-width: 768px) {
      width: 50%;
    }
    margin-bottom: 20px;
    &__head {
      font-weight: bold;
      margin-bottom: 5px;
    }
    &__body {
      font-size: $paragraph;
      line-height: 35px;
    }
  }
}

////////////////////////////////////////////////////////////////////////////

.intro-3 {
  position: relative;
  .heading {
    position: absolute;
    color: $white;
    @media (max-width: 767px) {
      font-size: $mHeading;
    }
  }
}

////////////////////////////////////////////////////////////////////////////

.intro-5 {
  &__box1 {
    background-color: $coldGrey;

    @extend %less-than-half;
    align-items: center;
    @media (min-width: 768px) {
      height: 589px;
      background-color: $coldGrey;
    }
  }
  &__box2 {
    @extend %more-than-half;
    background-color: $lightGrey;
    @media (min-width: 768px) {
      height: 589px;
      padding-left: 7%;
    }
    @media (max-width: 767px) {
      padding: 30px 15px 40px;
    }
    .heading {
      color: $coldGreen;
    }
    .paragraph {
      color: $coldGrey;
      @media (max-width: 767px) {
        margin-top: 10px;
      }
      @media (min-width: 1024px) {
        width: 90%;
      }
    }
    img {
      margin-right: 10px;
      position: relative;
      top: 3px;
    }
    .items {
      font-weight: 500;
      line-height: 1.5;
      .row {
        flex-direction: row;
        width: 75%;
      }
    }
  }
}

.m-img {
  background-color: $coldGrey;
  margin: 15px;
  padding: 1%;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}

////////////////////////////////////////////////////////////

.solutions {
  color: $coldGreen;
  padding: 7%;
  padding-bottom: 0;
  border-top: 1px solid $coldGreen;
  border-bottom: 1px solid $coldGreen;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    padding-right: 15px;
    padding-left: 15px;
    .exp {
      border-top: 1px solid $coldGreen;
      border-bottom: 1px solid $coldGreen;
      padding-left: 45px;
      .paragraph {
        border-left: 1px solid $coldGreen;
        margin: 0;
        padding: 30px 15px;
      }
    }
  }
  &__row1 {
    @media (min-width: 768px) {
      margin-bottom: 100px;
    }
  }
  .paragraph {
    color: $coldGreen;
  }

  &__options {
    @media (max-width: 767px) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      .row {
        flex: 0 0 auto;
        flex-direction: row;
        &:first-child {
          .column {
            &:first-child {
              padding-left: 0;
            }
          }
        }
        .column {
          width: 260px;
        }
      }
    }
    .column {
      padding: 10px;
      @media (min-width: 768px) {
        margin-bottom: 100px;
      }
      .options_body {
        @media (min-width: 768px) {
          width: 94%;
        }
      }
      .breadcrumb {
        color: $brightGreen;
        font-weight: 500;
        margin-top: 10px;
      }
      .subtitle {
        font-size: $mHeading;
        line-height: 1;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: normal;
        @media (min-width: 768px) {
          font-size: $subHeading;
        }
      }
      .text {
        color: $coldGrey;
        margin-top: 0;
        line-height: 1.5;
        font-weight: 500;
      }
    }
  }
}

//////////////

.big-img {
  position: relative;
  > div {
    position: absolute;
    color: $white;
    align-items: center;
    align-content: space-around;
    .heading {
      margin: revert;
      line-height: normal;
    }
  }
  .bg {
    min-height: 380px;
  }
}

//////////////////////////////////////////////////////// References

.references {
  margin-bottom: 20px;
  @media (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (min-width: 768px) {
    border-top: 1px solid $coldGreen;
    border-bottom: 1px solid $coldGreen;
    .column {
      padding-left: 7%;
      padding-right: 2%;
    }
  }

  &__box1 {
    @media (max-width: 767px) {
      border-top: 1px solid $coldGreen;
    }
    @media (min-width: 1024px) {
      width: 50%;
    }
  }
  &__box2 {
    @media (max-width: 767px) {
      border-bottom: 1px solid $coldGreen;
    }
    @media (min-width: 768px) {
      border-left: 1px solid $coldGreen;
    }
    @media (min-width: 1024px) {
      width: 50%;
    }
  }

  .heading {
    color: $coldGreen;
  }
  .paragraph {
    color: $coldGreen;
    @media (max-width: 767px) {
      border-top: 1px solid $coldGreen;
      border-bottom: 1px solid $coldGreen;
      padding-left: 30px;
      span {
        border-left: 1px solid $coldGreen;
        display: inline-block;
        padding: 30px 15px;
      }
    }
  }

  .logos {
    width: 90%;

    .row {
      justify-content: center;
      flex-direction: row;
    }

    img {
      filter: grayscale(100%);
      max-height: 75px;
      padding-right: 9%;
      max-width: 150px;

      @media (max-width: 767px) {
        max-width: 110px;
      }
    }
  }
}

//////////////////////////////////////////////////////// Contact

.contact {
  margin-bottom: 25px;
  background-color: $lightGrey;

  &__box1 {
    @extend %less-than-half;
    background-color: $coldGrey;
    align-items: center;
    padding: 5%;
    @media (min-width: 768px) {
      height: 589px;
    }
    @media (max-width: 767px) {
      padding: 15%;
    }
  }
  &__box2 {
    @extend %more-than-half;
    background-color: $lightGrey;
    padding: 10px 15px 40px;
    @media (min-width: 768px) {
      padding: 2% 5% 2% 7%;
    }
    .heading {
      color: $coldGreen;
    }
    .paragraph {
      color: $coldGrey;
      @media (max-width: 767px) {
        font-size: $baseText;
        font-weight: 500;
        line-height: 1.8;
        margin-top: 5px;
      }
    }

    form {
      @media (min-width: 1024px) {
        width: 75%;
      }
      @media (min-width: 1440px) {
        width: 63%;
      }
    }
    input {
      background-color: $lightGrey;
      width: 100%;
      color: $silverGrey;
      border-color: transparent;
      border-bottom: 1px solid $silverGrey;
      font-weight: 500;
      padding: 5px 0;
      margin: 15px 0;
      &:focus,
      &:active {
        outline: none;
      }
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
}

//////////////////////////////////////////////////////// footer

footer {
  font-weight: 500;
  padding-top: 30px;

  .row {
    justify-content: space-between;
    margin-bottom: 0;
  }
  a {
    color: $coldGrey;
    text-decoration: none;
  }
  .first {
    border-bottom: 1px solid $coldGreen;
    padding: 15px 0;
    @media (max-width: 767px) {
      > div {
        padding: 5px 0;
      }
    }
    .social {
      a {
        padding: 0 10px;
      }
    }
    .f-contact {
      a {
        padding-left: 15px;
      }
    }
  }
  .second {
    padding: 15px 0;
    @media (max-width: 767px) {
      > div {
        padding: 5px 0;
      }
    }
    .links {
      a {
        padding: 0 5px;
      }
    }
  }
}

.services-grid {
  height: 100%;
  display: flex;
  flex-direction: column;
  //background: #191919 ;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../img/codexist4.png);
  background-position: center center;
  text-shadow: 0 1px 0 black;

  & > :nth-child(2n) {
    .services-row {
      flex-direction: row-reverse;
      @media (max-width: 767px) {
        flex-direction: row;
      }
    }
  }

  .services-row {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    padding: 30px 0;

    & > * {
      height: 100%;
      flex: 1;
      padding: 30px;
    }

    .services-content {
      font-weight: 500;
      color: lighten(#939393, 25%);
      font-size: 22px;
    }
    .services-title {
      height: 100%;
      color: $brightGreen;
      font-size: $subHeading;
      font-weight: bold;
    }
  }
  .services-grid-item {
    //border-top: solid 1px $lightGrey;
    border-bottom: solid 1px $lightGrey;
    //border-left: 5px solid transparent;
    //border-right: 5px solid transparent;
    //border-bottom: 10px solid transparent;
  }
}
.google-maps {
  position: relative;
  padding-bottom: 33%;
  height: 0;
  width: 100%;
  overflow: hidden;
  @media (max-width: 767px) {
    padding-bottom: 66%;
  }
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}

.jobs {
  header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../img/jobs/jobs-2.jpeg");
    background-size: cover;
    height: 45vh;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    h1 {
      color: #fff;
      font-size: 3rem;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 1rem;
      text-align: center;
    }

    p {
      font-size: 1.3rem;
      text-transform: uppercase;
      color: #fff;
      margin: 0;
    }
  }

  &_title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    border-bottom: 1px solid black;
    font-size: 1rem;
    align-items: center;

    @media (max-width: 767px) {
      font-size: 0.7rem;
    }
  }

  &_panel {
    padding: 0 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }

  .column {
    flex-grow: 1;
    flex-basis: 0;
  }
  &_posting {
    line-height: 1.5;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    padding: 0 20px;

    @media (max-width: 767px) {
      max-width: 100vw;
    }

    @media (max-width: 980px) {
      max-width: 100%;
    }
  }
}
