
@import 'variables';

.btn {
   border: 1px solid $coldGreen;
   padding: 12px 35px;
   background: transparent;
   color: $coldGreen;
   text-decoration: none;
   &:hover {
      background-color: $coldGreen;
      color: $white;
   }
   &.btn-secondary{
      border-color: $brightGreen;
      color: $brightGreen;
      &:hover {
         background-color: $brightGreen;
         color: $coldGreen;
      }
   }
}