html {
   height: 100%;
}
body {
   height: 100%;
   background-color: #E5E5E5;
}
.btn {
  border: 1px solid #004650;
  padding: 12px 35px;
  background: transparent;
  color: #004650;
  text-decoration: none;
}
.btn:hover {
  background-color: #004650;
  color: #fff;
}
.btn.btn-secondary {
  border-color: #00DC96;
  color: #00DC96;
}
.btn.btn-secondary:hover {
  background-color: #00DC96;
  color: #004650;
}
* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-size: 15px;
  font-family: Neue Haas Unica Pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  background-color: #e6e6e6;
  margin: 0;
  overflow-x: hidden;
}
.container {
  background-color: #fff;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 767px) {
  .container {
    width: 100%;
    padding: 0;
  }
}
.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .row {
    flex-direction: column;
  }
}
.row.align-start {
  align-items: flex-start;
}
.column {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .column {
    width: 100%;
  }
}
.paragraph {
  font-size: 1.667em;
  line-height: 35px;
  color: #e6e6e6;
}
.heading {
  font-size: 5.333em;
  line-height: 75px;
  margin: 15px 0;
  font-weight: normal;
}
@media (max-width: 1024px) {
  .heading {
    font-size: 2.667em;
    line-height: 1;
  }
}
@media (min-width: 768px) {
  .contact__box2, .intro-5__box2 {
    width: 58%;
  }
}
@media (min-width: 768px) {
  .contact__box1, .intro-5__box1 {
    width: 42%;
  }
}
@media (min-width: 768px) {
  .intro-4__box2, .intro-4__box1, .col-6 {
    width: 50% !important;
  }
}
.img-fluid {
  width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .hide-m {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .hide-t {
    display: none !important;
  }
}
nav {
  background-color: #fff;
  padding: 20px 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  nav {
    background-color: #1e1e1e;
    padding: 15px;
  }
}
nav .brand .logo {
  height: 24px;
}
nav .links a {
  color: #1e1e1e;
  margin: 0 15px;
  text-decoration: none;
  font-weight: 500;
}
nav .links a:hover {
  text-decoration: underline;
}
nav .links-mobile {
  background-color: #1e1e1e;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: absolute;
  left: 0;
  top: 65px;
  width: 100%;
  padding: 30px 30px 30px 15px;
  transition: all 0.35s ease;
  border-top: 1px solid #fff;
}
nav .links-mobile a {
  text-decoration: none;
  margin: 10px 0;
  color: #fff;
}
nav .links-mobile a:first-child {
  margin-top: 0;
}
nav .links-mobile a:last-child {
  margin-bottom: 0;
}
nav .links-mobile.active {
  opacity: 1;
  visibility: visible;
}
.intro-1,
.intro-6 {
  background: #004650;
  color: #fff;
  padding-left: 7%;
  width: 58%;
  height: 589px;
}
.intro-1 .head,
.intro-6 .head {
  width: 90%;
  color: #e6e6e6;
  font-size: 1em;
  border-bottom: 1px solid #00dc96;
  padding-bottom: 8px;
}
.intro-1 p,
.intro-6 p {
  width: 90%;
}
@media (max-width: 767px) {
  .intro-1,
.intro-6 {
    width: 100%;
    height: auto;
    background-color: #191919;
    padding: 30px 15px 0;
  }
  .intro-1 .head,
.intro-6 .head {
    width: 100%;
  }
  .intro-1 .text-m,
.intro-6 .text-m {
    background-color: #004650;
  }
}
.intro-img-1,
.intro-img-6 {
  background-color: #004650;
  padding: 15px 15px 40px;
}
@media (max-width: 767px) {
  .intro-6 {
    background: #004650;
  }
}
.intro-6 .heading {
  color: #00dc96;
}
.intro-img-6 {
  background-color: #fff;
}
@media (min-width: 768px) {
  .img-field {
    width: 42%;
    height: 589px;
    background-color: #191919;
  }
}
.intro-2,
.intro-4 {
  border-top: 1px solid #004650;
  border-bottom: 1px solid #004650;
}
@media (max-width: 767px) {
  .intro-2,
.intro-4 {
    margin-right: 15px;
    margin-left: 15px;
  }
}
.intro-2__box1,
.intro-4__box1 {
  padding-left: 15px;
}
.intro-2__box1 .heading,
.intro-4__box1 .heading {
  color: #004650;
}
@media (max-width: 767px) {
  .intro-2__box1,
.intro-4__box1 {
    border-bottom: 1px solid #004650;
  }
}
@media (min-width: 768px) {
  .intro-2__box1,
.intro-4__box1 {
    width: 42%;
    padding-left: 7%;
  }
}
.intro-2__box2,
.intro-4__box2 {
  border-left: 1px solid #004650;
}
.intro-2__box2 .paragraph,
.intro-4__box2 .paragraph {
  color: #004650;
}
@media (min-width: 768px) {
  .intro-2__box2 .paragraph,
.intro-4__box2 .paragraph {
    width: 77%;
  }
}
@media (max-width: 767px) {
  .intro-2__box2,
.intro-4__box2 {
    margin-left: 60px;
    padding: 0 15px;
    margin-right: 30px;
  }
}
@media (min-width: 768px) {
  .intro-2__box2,
.intro-4__box2 {
    width: 58%;
    padding-left: 7%;
  }
}
.intro-4__box2 .paragraph {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .intro-4__box2 .paragraph {
    width: 85%;
  }
}
@media (max-width: 767px) {
  .intro-4__box2 .row {
    margin-bottom: 0;
  }
}
.intro-4 .item {
  color: #1e1e1e;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .intro-4 .item {
    width: 50%;
  }
}
.intro-4 .item__head {
  font-weight: bold;
  margin-bottom: 5px;
}
.intro-4 .item__body {
  font-size: 1.667em;
  line-height: 35px;
}
.intro-3 {
  position: relative;
}
.intro-3 .heading {
  position: absolute;
  color: #fff;
}
@media (max-width: 767px) {
  .intro-3 .heading {
    font-size: 1.333em;
  }
}
.intro-5__box1 {
  background-color: #1e1e1e;
  align-items: center;
}
@media (min-width: 768px) {
  .intro-5__box1 {
    height: 589px;
    background-color: #1e1e1e;
  }
}
.intro-5__box2 {
  background-color: #e6e6e6;
}
@media (min-width: 768px) {
  .intro-5__box2 {
    height: 589px;
    padding-left: 7%;
  }
}
@media (max-width: 767px) {
  .intro-5__box2 {
    padding: 30px 15px 40px;
  }
}
.intro-5__box2 .heading {
  color: #004650;
}
.intro-5__box2 .paragraph {
  color: #1e1e1e;
}
@media (max-width: 767px) {
  .intro-5__box2 .paragraph {
    margin-top: 10px;
  }
}
@media (min-width: 1024px) {
  .intro-5__box2 .paragraph {
    width: 90%;
  }
}
.intro-5__box2 img {
  margin-right: 10px;
  position: relative;
  top: 3px;
}
.intro-5__box2 .items {
  font-weight: 500;
  line-height: 1.5;
}
.intro-5__box2 .items .row {
  flex-direction: row;
  width: 75%;
}
.m-img {
  background-color: #1e1e1e;
  margin: 15px;
  padding: 1%;
}
.m-img img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.solutions {
  color: #004650;
  padding: 7%;
  padding-bottom: 0;
  border-top: 1px solid #004650;
  border-bottom: 1px solid #004650;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .solutions {
    padding-right: 15px;
    padding-left: 15px;
  }
  .solutions .exp {
    border-top: 1px solid #004650;
    border-bottom: 1px solid #004650;
    padding-left: 45px;
  }
  .solutions .exp .paragraph {
    border-left: 1px solid #004650;
    margin: 0;
    padding: 30px 15px;
  }
}
@media (min-width: 768px) {
  .solutions__row1 {
    margin-bottom: 100px;
  }
}
.solutions .paragraph {
  color: #004650;
}
@media (max-width: 767px) {
  .solutions__options {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .solutions__options .row {
    flex: 0 0 auto;
    flex-direction: row;
  }
  .solutions__options .row:first-child .column:first-child {
    padding-left: 0;
  }
  .solutions__options .row .column {
    width: 260px;
  }
}
.solutions__options .column {
  padding: 10px;
}
@media (min-width: 768px) {
  .solutions__options .column {
    margin-bottom: 100px;
  }
}
@media (min-width: 768px) {
  .solutions__options .column .options_body {
    width: 94%;
  }
}
.solutions__options .column .breadcrumb {
  color: #00dc96;
  font-weight: 500;
  margin-top: 10px;
}
.solutions__options .column .subtitle {
  font-size: 1.333em;
  line-height: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: normal;
}
@media (min-width: 768px) {
  .solutions__options .column .subtitle {
    font-size: 2.667em;
  }
}
.solutions__options .column .text {
  color: #1e1e1e;
  margin-top: 0;
  line-height: 1.5;
  font-weight: 500;
}
.big-img {
  position: relative;
}
.big-img > div {
  position: absolute;
  color: #fff;
  align-items: center;
  align-content: space-around;
}
.big-img > div .heading {
  margin: revert;
  line-height: normal;
}
.big-img .bg {
  min-height: 380px;
}
.references {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .references {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 768px) {
  .references {
    border-top: 1px solid #004650;
    border-bottom: 1px solid #004650;
  }
  .references .column {
    padding-left: 7%;
    padding-right: 2%;
  }
}
@media (max-width: 767px) {
  .references__box1 {
    border-top: 1px solid #004650;
  }
}
@media (min-width: 1024px) {
  .references__box1 {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .references__box2 {
    border-bottom: 1px solid #004650;
  }
}
@media (min-width: 768px) {
  .references__box2 {
    border-left: 1px solid #004650;
  }
}
@media (min-width: 1024px) {
  .references__box2 {
    width: 50%;
  }
}
.references .heading {
  color: #004650;
}
.references .paragraph {
  color: #004650;
}
@media (max-width: 767px) {
  .references .paragraph {
    border-top: 1px solid #004650;
    border-bottom: 1px solid #004650;
    padding-left: 30px;
  }
  .references .paragraph span {
    border-left: 1px solid #004650;
    display: inline-block;
    padding: 30px 15px;
  }
}
.references .logos {
  width: 90%;
}
.references .logos .row {
  justify-content: center;
  flex-direction: row;
}
.references .logos img {
  filter: grayscale(100%);
  max-height: 75px;
  padding-right: 9%;
  max-width: 150px;
}
@media (max-width: 767px) {
  .references .logos img {
    max-width: 110px;
  }
}
.contact {
  margin-bottom: 25px;
  background-color: #e6e6e6;
}
.contact__box1 {
  background-color: #1e1e1e;
  align-items: center;
  padding: 5%;
}
@media (min-width: 768px) {
  .contact__box1 {
    height: 589px;
  }
}
@media (max-width: 767px) {
  .contact__box1 {
    padding: 15%;
  }
}
.contact__box2 {
  background-color: #e6e6e6;
  padding: 10px 15px 40px;
}
@media (min-width: 768px) {
  .contact__box2 {
    padding: 2% 5% 2% 7%;
  }
}
.contact__box2 .heading {
  color: #004650;
}
.contact__box2 .paragraph {
  color: #1e1e1e;
}
@media (max-width: 767px) {
  .contact__box2 .paragraph {
    font-size: 1em;
    font-weight: 500;
    line-height: 1.8;
    margin-top: 5px;
  }
}
@media (min-width: 1024px) {
  .contact__box2 form {
    width: 75%;
  }
}
@media (min-width: 1440px) {
  .contact__box2 form {
    width: 63%;
  }
}
.contact__box2 input {
  background-color: #e6e6e6;
  width: 100%;
  color: #939393;
  border-color: transparent;
  border-bottom: 1px solid #939393;
  font-weight: 500;
  padding: 5px 0;
  margin: 15px 0;
}
.contact__box2 input:focus, .contact__box2 input:active {
  outline: none;
}
.contact__box2 input:last-child {
  margin-bottom: 10px;
}
footer {
  font-weight: 500;
  padding-top: 30px;
}
footer .row {
  justify-content: space-between;
  margin-bottom: 0;
}
footer a {
  color: #1e1e1e;
  text-decoration: none;
}
footer .first {
  border-bottom: 1px solid #004650;
  padding: 15px 0;
}
@media (max-width: 767px) {
  footer .first > div {
    padding: 5px 0;
  }
}
footer .first .social a {
  padding: 0 10px;
}
footer .first .f-contact a {
  padding-left: 15px;
}
footer .second {
  padding: 15px 0;
}
@media (max-width: 767px) {
  footer .second > div {
    padding: 5px 0;
  }
}
footer .second .links a {
  padding: 0 5px;
}
.services-grid {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(codexist4.db3a5470.png);
  background-position: center center;
  text-shadow: 0 1px 0 black;
}
.services-grid > :nth-child(2n) .services-row {
  flex-direction: row-reverse;
}
@media (max-width: 767px) {
  .services-grid > :nth-child(2n) .services-row {
    flex-direction: row;
  }
}
.services-grid .services-row {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  padding: 30px 0;
}
.services-grid .services-row > * {
  height: 100%;
  flex: 1;
  padding: 30px;
}
.services-grid .services-row .services-content {
  font-weight: 500;
  color: lightgray;
  font-size: 22px;
}
.services-grid .services-row .services-title {
  height: 100%;
  color: #00dc96;
  font-size: 2.667em;
  font-weight: bold;
}
.services-grid .services-grid-item {
  border-bottom: solid 1px #e6e6e6;
}
.google-maps {
  position: relative;
  padding-bottom: 33%;
  height: 0;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 767px) {
  .google-maps {
    padding-bottom: 66%;
  }
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
.jobs header {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("jobs-2.c9ded914.jpeg");
  background-size: cover;
  height: 45vh;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
.jobs header h1 {
  color: #fff;
  font-size: 3rem;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 1rem;
  text-align: center;
}
.jobs header p {
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
}
.jobs_title {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  border-bottom: 1px solid black;
  font-size: 1rem;
  align-items: center;
}
@media (max-width: 767px) {
  .jobs_title {
    font-size: 0.7rem;
  }
}
.jobs_panel {
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
.jobs .column {
  flex-grow: 1;
  flex-basis: 0;
}
.jobs_posting {
  line-height: 1.5;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .jobs_posting {
    max-width: 100vw;
  }
}
@media (max-width: 980px) {
  .jobs_posting {
    max-width: 100%;
  }
}
/*# sourceMappingURL=index.dc1ac2ef.css.map */
